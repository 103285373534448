import { render, staticRenderFns } from "./DeathCertificate2.vue?vue&type=template&id=7c85c1a8"
import script from "./DeathCertificate2.vue?vue&type=script&lang=js"
export * from "./DeathCertificate2.vue?vue&type=script&lang=js"
import style0 from "./DeathCertificate2.vue?vue&type=style&index=0&id=7c85c1a8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports